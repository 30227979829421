import React from "react";
import "./footer.css";
import logo from "../../assets/logo.svg";
import {
  BiBulb,
  BiBriefcaseAlt,
  BiCalendar,
  BiBookReader,
  BiCalendarAlt,
  BiChalkboard,
  BiGroup,
  BiCheckSquare,
  BiNetworkChart,
  BiCartAlt,
  BiStoreAlt,
  BiDesktop,
} from "react-icons/bi";

const Footer = () => {
  return (
    <div className="footer-whole">
      <div className="footer-container">
        <div className="footer-links">
          <div className="footer-row ">
            <div className="footer-link-logo">
              <div className="footer-logo">
                <a href="https://uic.edu">
                  <img src={logo} alt="uic logo" aria-label="logo" />
                </a>
              </div>
              <div className="footer--address">
                <p>1200 West Harrison St</p>
                <p>Chicago, Illinois 60607</p>
                <p style={{ marginTop: 20 }}>(312) 996-7000</p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-links-data">
          {/* <div className="footer-list-title">
            <h2>QUICK LINKS</h2>
          </div> */}
          <div className="footer-col">
            <div className="footer-row-links">
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="Learning Technology Solutions"
                    href="https://learning.uic.edu/"
                  >
                    <BiBulb size={23} style={{ marginRight: 15 }} />
                    <p>LTS</p>
                  </a>
                </div>
              </div>
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="Career Services link"
                    href="https://careerservices.uic.edu/"
                  >
                    <BiBriefcaseAlt size={23} style={{ marginRight: 15 }} />
                    <p>Career Services</p>
                  </a>
                </div>
              </div>
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="Schedule of Classes"
                    href="https://banner.apps.uillinois.edu/StudentRegistrationSSB/ssb/term/termSelection?mode=search&mepCode=2UIC"
                  >
                    <BiCalendar size={23} style={{ marginRight: 15 }} />
                    <p>Schedule of Classes</p>
                  </a>
                </div>
              </div>
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="UIC Library"
                    href="http://library.uic.edu/"
                  >
                    <BiBookReader size={23} style={{ marginRight: 15 }} />
                    <p>UIC Library</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-row-links">
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="Academic Calendar"
                    href="http://catalog.uic.edu/ucat/academic-calendar/"
                  >
                    <BiCalendarAlt size={23} style={{ marginRight: 15 }} />
                    <p>Academic Calendar</p>
                  </a>
                </div>
              </div>
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="Linkedin Learning"
                    href="http://go.uic.edu/linkedinlearning"
                  >
                    <BiChalkboard size={23} style={{ marginRight: 15 }} />
                    <p>Linkedin Learning</p>
                  </a>
                </div>
              </div>
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="Student Services"
                    href="http://www.uic.edu/uic/studentlife/studentservices/"
                  >
                    <BiGroup size={23} style={{ marginRight: 15 }} />
                    <p>Student Services</p>
                  </a>
                </div>
              </div>
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="Turbo Vote"
                    href="https://uic.turbovote.org/?r=blackboard"
                  >
                    <BiCheckSquare size={23} style={{ marginRight: 15 }} />
                    <p>Turbo Vote (Get Registered)</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-row-links">
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="UIC Connection"
                    href="http://connect.uic.edu/"
                  >
                    <BiNetworkChart size={23} style={{ marginRight: 15 }} />
                    <p>UIC Connection</p>
                  </a>
                </div>
              </div>
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="Web Store"
                    href="http://webstore.illinois.edu/home/"
                  >
                    <BiCartAlt size={23} style={{ marginRight: 15 }} />
                    <p>Web Store</p>
                  </a>
                </div>
              </div>
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="UIC Bookstore"
                    href="https://www.bkstr.com/uicbookstore/shop/textbooks-and-course-materials"
                  >
                    <BiStoreAlt size={23} style={{ marginRight: 15 }} />
                    <p>UIC Bookstore</p>
                  </a>
                </div>
              </div>
              <div className="footer-col-row-link">
                <div>
                  <a
                    style={{ display: "flex", flexDirection: "row" }}
                    title="Technology Solutions"
                    href="https://it.uic.edu/services-support/student-resources/"
                  >
                    <BiDesktop size={23} style={{ marginRight: 15 }} />
                    <p>Technology Solutions</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
