import Navbar from './Navbar';
import React from 'react';
import logo from "../../../assets/CAMP.HRZ.SM.RGB.SVG";
import logoCircle from "../../../assets/CAMP.CIRC.SM.RGB.SVG";

const Header = () => {
    return (
        <header>
            <div className="nav-area">
                <div className="etis__navbar-links_logo">
                    <a href="/">
                        <img
                            src={logo}
                            alt="uic logo"
                            aria-label="logo"
                            className="brand-logo"
                        />
                        <img
                            src={logoCircle}
                            alt="uic logo"
                            aria-label="logo"
                            className="circle-logo"
                        />
                    </a>
                    {/* <a href="/">
                       
                    </a> */}
                </div>

                <Navbar />
            </div>
        </header>
    );
};

export default Header;