import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useSpeechSynthesis } from "react-speech-kit";

import { AiOutlineArrowRight } from "react-icons/ai";
import { GiSpeaker } from "react-icons/gi";
import { Dna } from "react-loader-spinner";

import "./header.css";
import blackboard from "../../assets/blackboard.webp";
import { closestOnSegment } from "ol/coordinate";
// import card_data from "../../stories.json";

const Story1 = ({
  card_data,
  story_num,
  speak,
  cancel,
  speaking,
  storyCount,
}) => {
  let story_id = story_num;
  var story_img_src = card_data[String(story_id)].img_src;
  return (
    <>
      <div className="box-field-1" aria-label="story1">
        <div className="image_container">
          {/* alt={card_data[String(story_id)].alt_text} */}
          <img src={story_img_src} alt={card_data[String(story_id)].alt_text} />
          <h2 tabIndex="0">{card_data[String(story_id)].heading} </h2>
          <div className="text">
            <p tabIndex="0">{card_data[String(story_id)].text}</p>
          </div>
          <ul>
            <li>
              <a href={card_data[String(story_id)].link} target="_blank" rel="noreferrer">
                {card_data[String(story_id)].link_text}
                <AiOutlineArrowRight />
              </a>
            </li>
          </ul>
          <button
            className="speaker"
            onClick={() => {
              if (!speaking) {
                speak({ text: card_data[String(story_id)].text });
              } else {
                cancel();
              }
            }}>
            <GiSpeaker /> Listen
          </button>
        </div>
      </div>
    </>
  );
};
const Story2 = ({
  card_data,
  story_num,
  speak,
  cancel,
  speaking,
  storyCount,
}) => {
  let story_id = story_num + 1;
  if (story_id === card_data.length) {
    story_id = 0;
  }
  var story_img_src = card_data[String(story_id)].img_src;
  return (
    <>
      <div className="box-field-2" aria-label="story2">
        <div className="image_container">
          {/* alt= {card_data[String(story_id)].alt_text} */}
          <img src={story_img_src} alt={card_data[String(story_id)].alt_text} />
          <h2 tabIndex="0">{card_data[String(story_id)].heading} </h2>
          <div className="text">
            <p tabIndex="0">{card_data[String(story_id)].text}</p>
          </div>
          <ul>
            <li>
              <a href={card_data[String(story_id)].link} target="_blank" rel="noreferrer">
                {card_data[String(story_id)].link_text}
                <AiOutlineArrowRight />
              </a>
            </li>
          </ul>
          <button
            className="speaker"
            onClick={() => {
              if (!speaking) {
                speak({ text: card_data[String(story_id)].text });
              } else {
                cancel();
              }
            }}>
            <GiSpeaker /> Listen
          </button>
        </div>
      </div>
    </>
  );
};

const Signin = () => {
  return (
    <>
      <div className="box-field">
        <div className="image_container">
          <img src={blackboard} alt="Student working with Blackboard" />
          <h2 tabIndex="0">Blackboard</h2>
          <div className="text">
            <p tabIndex="0">
              Preparing students for success in their careers and in life is our
              constant mission. Whether taking classes in person or online, we
              are committed to providing every resource necessary to UIC
              students. Blackboard Learning Management Systems creates digital
              learning spaces that enable academic collaboration.
            </p>
          </div>
          <ul>
            <li>
              <div className="signin__button">
                <a
                  href="https://uic.blackboard.com/auth-saml/saml/login?apId=_150_1&redirectUrl=https%3A%2F%2Fuic.blackboard.com%2Fultra"
                  id="loginurl"
                >
                  <button aria-label="login button">Sign In</button>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const Header = () => {
  const { speak, cancel, speaking } = useSpeechSynthesis();
  const [card_data, setCard_data] = useState();
  const [loading, setLoading] = useState(true);
  // const [storyCount, setStoryCount] = useState(0);
  const [story_num, setStory_num] = useState(0);

  useEffect(() => {
    if (!speaking) {
      cancel();
    }
  });

  // useEffect(() => {
  //   fetch(
  //     // "/bbcswebdav/orgs/bb.login.stories.work/LoginStories/stories/stories.json"
  //     "./stories.json",
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((usefulData) => {
  //       // setStoryCount(Object.keys(usefulData).length);
  //       // console.log("count", Object.keys(usefulData).length);
  //       // setStory_num(checkCookie(Object.keys(usefulData).length));
  //     })
  //     .catch((e) => {
  //       console.log(e.message);
  //     });
  // }, []);

  useEffect(() => {
    fetch(
      "/bbcswebdav/orgs/bb.login.stories.work/LoginStories/stories/stories.json"
      // "./stories.json",
      // {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //   },
      // }
    )
      .then((response) => response.json())
      .then((usefulData) => {
        setCard_data(usefulData);
        // setStoryCount(Object.keys(usefulData).length);
        // console.log("count", Object.keys(usefulData).length);
        setStory_num(checkCookie(Object.keys(usefulData).length));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);

        setCard_data({
          0: {
            heading: "Undergraduate Internships",
            img_src:
              "/bbcswebdav/orgs/bb.login.stories.work/LoginStories/images/Employment%20Support.jpg",
            alt_text: "Undergraduate Internships",
            text: "Navigating internships as an undergraduate can be confusing. One may ask themselves; is it more important that I do research or that I start getting work experience as an intern in my field of work? All students want to be ready to translate to their career after college but many students are unsure of what it takes to get there and be competitive candidates. ",
            link: "https://dos.uic.edu/student-assistance/uicare/care-facts/#undergraduate-internships",
            link_text: "Care Facts",
          },
          1: {
            heading: "Student Behavior",
            img_src:
              "/bbcswebdav/orgs/bb.login.stories.work/LoginStories/images/Balancing%20School%20and%20Life.jpg",
            alt_text: "Student Behavior",
            text: "Community Standards empowers students to uphold the community values of integrity, accountability, and respect. In instances where student’s behavioral choices are potentially in conflict with these values, the office facilitates a variety of methods meant to fairly resolve conflicts, hold students found responsible accountable, and spur their ethical and educational development.",
            link: "https://dos.uic.edu/wp-content/uploads/sites/262/2021/08/Student-Behavior-Card.pdf",
            link_text: "Learn More",
          },
          2: {
            heading: "Practicing Gratitude",
            img_src:
              "/bbcswebdav/orgs/bb.login.stories.work/LoginStories/images/Adjusting%20to%20Campus%20Life.jpg",
            alt_text: "Practicing gratitude",
            text: "Sometimes, when life is moving fast there is little time to breathe. In times when the world feels heavy and difficult, it is often tough to see the good in our lives. The UIC Wellness Center provides resources on how to practice gratitude, and how it can look different for different people.",
            link: "https://dos.uic.edu/student-assistance/uicare/care-facts/",
            link_text: "Care Facts",
          },
          3: {
            heading: "Utility Assistance",
            img_src:
              "/bbcswebdav/orgs/bb.login.stories.work/LoginStories/images/Employment%20Support.jpg",
            alt_text: "Utility Assistance",
            text: "It can be difficult to keep up with rising gas and electric bills, especially when we find ourselves using it more often. How do we keep up with these payments while also budgeting for other necessities? The Community and Economic Development Association of Cook County, Inc. (CEDA) is one of the largest private, nonprofit Community Action Agencies in the country, serving residents throughout Cook County, Illinois.",
            link: "https://dos.uic.edu/student-assistance/uicare/care-facts/#utility-assistance",
            link_text: "Learn More",
          },
          4: {
            heading: "UIC tutoring programs",
            img_src:
              "/bbcswebdav/orgs/bb.login.stories.work/LoginStories/images/Employment%20Support.jpg",
            alt_text: "UIC tutoring programs",
            text: "UIC offers a wide variety of tutoring services to help you achieve your academic goals. One experience: My tutoring experience with CHANCE can be summed up with three words: efficient, effective, and most importantly fun. The CHANCE Program has offered me a plethora of resources that allowed me to maximize my study potential.",
            link: "https://tutoring.uic.edu/",
            link_text: "Learn More",
          },
          5: {
            heading: "Discover with LinkedIn Learning",
            img_src:
              "/bbcswebdav/orgs/bb.login.stories.work/LoginStories/images/Internships.jpg",
            alt_text: "LinkedIn Learning",
            text: "LinkedIn Learning’s team of industry experts is focused on creating engaging content that's relevant to your employees, impactful to your business, and produced in a way that keeps learners coming back for more.",
            link: "https://learning.linkedin.com/content-library",
            link_text: "Learn More",
          },
        });
        // setStoryCount(6);
        setStory_num(0);
        setLoading(false);
      });
  }, []);

  function getCookie(cname) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (cname === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function checkCookie(count) {
    let i = 0;
    if (getCookie("storyIndex") == null) {
      // console.log("cookie not found");
      setCookie("storyIndex", "0", 365);
    } else {
      // console.log("cookie found");
      // console.log("count in checkCookie: ", count);
      i = parseInt(getCookie("storyIndex"));
      i = i + 2;
      // console.log("i in checkCookie before if: ", i);
      if (i >= count) {
        i = i % count;
        // console.log("i in checkCookie: ", i);
      }
      setCookie("storyIndex", i, 365);
    }

    return i;
  }

  return (
    <>
      <div>
        {loading && card_data === undefined ? (
          <div className="rowC">
            <div
              style={{
                alignItems: "center",
                alignSelf: "center",
                padding: "10% 10% 10% 10%",
              }}
            >
              <Dna
                visible={true}
                height="100"
                width="100"
                ariaLabel="dna-loading"
                wrapperStyle={{ alignItems: "center", alignSelf: "center" }}
                wrapperClass="dna-wrapper"
              />
            </div>
            <div
              style={{
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Dna
                visible={true}
                height="100"
                width="100"
                ariaLabel="dna-loading"
                wrapperStyle={{ alignItems: "center", alignSelf: "center" }}
                wrapperClass="dna-wrapper"
              />
            </div>
            <Signin />
          </div>
        ) : (
          <div className="rowC">
            <Story1
              card_data={card_data}
              story_num={story_num}
              speak={speak}
              cancel={cancel}
              speaking={speaking}
              // storyCount={storyCount}
            />
            <Story2
              card_data={card_data}
              story_num={story_num}
              speak={speak}
              cancel={cancel}
              speaking={speaking}
              // storyCount={storyCount}
            />
            <Signin />
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
