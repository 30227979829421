import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";

import AnimatedNumbers from "react-animated-numbers";

import "./stats.css";

ChartJS.register(...registerables);

// ReactModal.setAppElement(document.getElementById("root"));

const Stats = () => {
  const [cookieValue, setCookieValue] = useState();
  const [showModal, setShowModal] = useState(false);
  const [stats, setStats] = useState([
    {
      id: 1,
      "Stat Text": "",
      "Stat Number": "20",
      "Stat Domain": "IN DIVERSITY",
      "Stat Explain":
        "Chicago's largest and most diverse university - U.S. News & World Report",
    },
    {
      id: 2,
      "Stat Text": "",
      "Stat Number": "34,000",
      "Stat Domain": "Students",
      "Stat Explain":
        "More than one-third of undergraduates are first-generation students.",
    },
    {
      id: 3,
      "Stat Text": "",
      "Stat Number": "10",
      "Stat Domain": "BEST VALUE",
      "Stat Explain":
        "Illinois' only university ranked 'Best Value' in the nation (#9) - Wall Street Journal",
    },
  ]);

  function getCookie(cname) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (cname === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function checkCookie() {
    var i = 0;

    if (getCookie("statIndex") == null) {
      setCookie("statIndex", "0", 365);
    } else {
      i = parseInt(getCookie("statIndex"));
      i = i + 3;
      if (i > 6) {
        i = 0;
      }
      setCookie("statIndex", i, 365);
    }
    return i;
  }

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const cookieValue = await checkCookie();
      setCookieValue(cookieValue);
      const data = await fetch(
        `https://bb-coprod-2.cc.uic.edu/dashboard/public/stats.php?index=${cookieValue}`
      );
      const json = await data.json();

      setStats(json.stats);
    };

    // call the function
    fetchData().catch(console.error);
  }, []);

  const [chartData, setChartData] = useState({
    data: {
      datasets: [
        {
          type: "bar",
          data: [
            { x: "2022-08-16", y: "79" },
            { x: "2022-08-17", y: "72" },
            { x: "2022-08-18", y: "142" },
            { x: "2022-08-19", y: "159" },
            { x: "2022-08-20", y: "330" },
            { x: "2022-08-21", y: "723" },
            { x: "2022-08-22", y: "3146" },
            { x: "2022-08-23", y: "4478" },
            { x: "2022-08-24", y: "4409" },
            { x: "2022-08-25", y: "4962" },
            { x: "2022-08-26", y: "3780" },
            { x: "2022-08-27", y: "2280" },
            { x: "2022-08-28", y: "4804" },
            { x: "2022-08-29", y: "3398" },
            { x: "2022-08-30", y: "3556" },
            { x: "2022-08-31", y: "3734" },
            { x: "2022-09-01", y: "4481" },
            { x: "2022-09-02", y: "3414" },
            { x: "2022-09-03", y: "1846" },
            { x: "2022-09-04", y: "3408" },
            { x: "2022-09-05", y: "2234" },
            { x: "2022-09-06", y: "3765" },
            { x: "2022-09-07", y: "3904" },
            { x: "2022-09-08", y: "4050" },
            { x: "2022-09-09", y: "2902" },
            { x: "2022-09-10", y: "1880" },
            { x: "2022-09-11", y: "4016" },
            { x: "2022-09-12", y: "3379" },
            { x: "2022-09-13", y: "3295" },
            { x: "2022-09-14", y: "3609" },
            { x: "2022-09-15", y: "3903" },
            { x: "2022-09-16", y: "3078" },
            { x: "2022-09-17", y: "1965" },
            { x: "2022-09-18", y: "4410" },
            { x: "2022-09-19", y: "2601" },
            { x: "2022-09-20", y: "3229" },
            { x: "2022-09-21", y: "3676" },
            { x: "2022-09-22", y: "3557" },
            { x: "2022-09-23", y: "2813" },
            { x: "2022-09-24", y: "1546" },
            { x: "2022-09-25", y: "3954" },
            { x: "2022-09-26", y: "2282" },
            { x: "2022-09-27", y: "2697" },
            { x: "2022-09-28", y: "2605" },
            { x: "2022-09-29", y: "3127" },
            { x: "2022-09-30", y: "2595" },
            { x: "2022-10-01", y: "1513" },
            { x: "2022-10-02", y: "3536" },
            { x: "2022-10-03", y: "2545" },
            { x: "2022-10-04", y: "2412" },
            { x: "2022-10-05", y: "2929" },
            { x: "2022-10-06", y: "2826" },
            { x: "2022-10-07", y: "2375" },
            { x: "2022-10-08", y: "1274" },
            { x: "2022-10-09", y: "3062" },
            { x: "2022-10-10", y: "2365" },
            { x: "2022-10-11", y: "2812" },
            { x: "2022-10-12", y: "3174" },
            { x: "2022-10-13", y: "3117" },
            { x: "2022-10-14", y: "2448" },
            { x: "2022-10-15", y: "1604" },
            { x: "2022-10-16", y: "4040" },
            { x: "2022-10-17", y: "2779" },
            { x: "2022-10-18", y: "3141" },
            { x: "2022-10-19", y: "3309" },
            { x: "2022-10-20", y: "3062" },
            { x: "2022-10-21", y: "2929" },
            { x: "2022-10-22", y: "1297" },
            { x: "2022-10-23", y: "3025" },
            { x: "2022-10-24", y: "2486" },
            { x: "2022-10-25", y: "2668" },
            { x: "2022-10-26", y: "3006" },
            { x: "2022-10-27", y: "3191" },
            { x: "2022-10-28", y: "2286" },
            { x: "2022-10-29", y: "1144" },
            { x: "2022-10-30", y: "2887" },
            { x: "2022-10-31", y: "2506" },
            { x: "2022-11-01", y: "3023" },
            { x: "2022-11-02", y: "3095" },
            { x: "2022-11-03", y: "3629" },
            { x: "2022-11-04", y: "3408" },
            { x: "2022-11-05", y: "1256" },
            { x: "2022-11-06", y: "3502" },
            { x: "2022-11-07", y: "2093" },
            { x: "2022-11-08", y: "2111" },
            { x: "2022-11-09", y: "3030" },
            { x: "2022-11-10", y: "3352" },
            { x: "2022-11-11", y: "2815" },
            { x: "2022-11-12", y: "1375" },
            { x: "2022-11-13", y: "3286" },
            { x: "2022-11-14", y: "2471" },
            { x: "2022-11-15", y: "2448" },
            { x: "2022-11-16", y: "2844" },
            { x: "2022-11-17", y: "3144" },
            { x: "2022-11-18", y: "2467" },
            { x: "2022-11-19", y: "1086" },
            { x: "2022-11-20", y: "2689" },
            { x: "2022-11-21", y: "1803" },
            { x: "2022-11-22", y: "1795" },
            { x: "2022-11-23", y: "1439" },
            { x: "2022-11-24", y: "821" },
            { x: "2022-11-25", y: "1188" },
            { x: "2022-11-26", y: "1043" },
            { x: "2022-11-27", y: "2319" },
            { x: "2022-11-28", y: "1920" },
            { x: "2022-11-29", y: "2158" },
            { x: "2022-11-30", y: "2056" },
            { x: "2022-12-01", y: "2007" },
            { x: "2022-12-02", y: "2193" },
            { x: "2022-12-03", y: "874" },
            { x: "2022-12-04", y: "1423" },
            { x: "2022-12-05", y: "869" },
            { x: "2022-12-06", y: "857" },
            { x: "2022-12-07", y: "1033" },
            { x: "2022-12-08", y: "848" },
            { x: "2022-12-09", y: "932" },
            { x: "2022-12-10", y: "447" },
            { x: "2022-12-11", y: "361" },
            { x: "2022-12-12", y: "122" },
          ],
          label: "DIGITAL DISCUSSIONS submitted",
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(255, 159, 64, 0.2)",
            "rgba(255, 205, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(201, 203, 207, 0.2)",
          ],
          borderColor: [
            "rgb(255, 99, 132)",
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
            "rgb(201, 203, 207)",
          ],
          borderWidth: "1",
        },
        {
          type: "line",
          data: [
            { x: "2022-08-16", y: "45", t: "21" },
            { x: "2022-08-17", y: "59", t: "27" },
            { x: "2022-08-18", y: "88", t: "39" },
            { x: "2022-08-19", y: "115", t: "53" },
            { x: "2022-08-20", y: "180", t: "76" },
            { x: "2022-08-21", y: "430", t: "117" },
            { x: "2022-08-22", y: "1936", t: "274" },
            { x: "2022-08-23", y: "2834", t: "363" },
            { x: "2022-08-24", y: "2805", t: "414" },
            { x: "2022-08-25", y: "2917", t: "438" },
            { x: "2022-08-26", y: "2137", t: "431" },
            { x: "2022-08-27", y: "1264", t: "369" },
            { x: "2022-08-28", y: "2493", t: "437" },
            { x: "2022-08-29", y: "2128", t: "447" },
            { x: "2022-08-30", y: "2187", t: "447" },
            { x: "2022-08-31", y: "2352", t: "431" },
            { x: "2022-09-01", y: "2573", t: "402" },
            { x: "2022-09-02", y: "1816", t: "360" },
            { x: "2022-09-03", y: "994", t: "296" },
            { x: "2022-09-04", y: "1653", t: "317" },
            { x: "2022-09-05", y: "1326", t: "362" },
            { x: "2022-09-06", y: "2296", t: "428" },
            { x: "2022-09-07", y: "2376", t: "410" },
            { x: "2022-09-08", y: "2453", t: "402" },
            { x: "2022-09-09", y: "1673", t: "363" },
            { x: "2022-09-10", y: "1007", t: "280" },
            { x: "2022-09-11", y: "2080", t: "394" },
            { x: "2022-09-12", y: "1954", t: "408" },
            { x: "2022-09-13", y: "2002", t: "403" },
            { x: "2022-09-14", y: "2179", t: "394" },
            { x: "2022-09-15", y: "2252", t: "357" },
            { x: "2022-09-16", y: "1746", t: "329" },
            { x: "2022-09-17", y: "959", t: "270" },
            { x: "2022-09-18", y: "2148", t: "343" },
            { x: "2022-09-19", y: "1526", t: "382" },
            { x: "2022-09-20", y: "1950", t: "403" },
            { x: "2022-09-21", y: "2305", t: "388" },
            { x: "2022-09-22", y: "2118", t: "352" },
            { x: "2022-09-23", y: "1529", t: "302" },
            { x: "2022-09-24", y: "821", t: "241" },
            { x: "2022-09-25", y: "1901", t: "299" },
            { x: "2022-09-26", y: "1445", t: "343" },
            { x: "2022-09-27", y: "1664", t: "311" },
            { x: "2022-09-28", y: "1686", t: "310" },
            { x: "2022-09-29", y: "1834", t: "300" },
            { x: "2022-09-30", y: "1450", t: "271" },
            { x: "2022-10-01", y: "793", t: "239" },
            { x: "2022-10-02", y: "1727", t: "289" },
            { x: "2022-10-03", y: "1469", t: "305" },
            { x: "2022-10-04", y: "1397", t: "310" },
            { x: "2022-10-05", y: "1719", t: "314" },
            { x: "2022-10-06", y: "1661", t: "311" },
            { x: "2022-10-07", y: "1262", t: "266" },
            { x: "2022-10-08", y: "632", t: "213" },
            { x: "2022-10-09", y: "1501", t: "275" },
            { x: "2022-10-10", y: "1503", t: "360" },
            { x: "2022-10-11", y: "1725", t: "353" },
            { x: "2022-10-12", y: "2011", t: "383" },
            { x: "2022-10-13", y: "1753", t: "340" },
            { x: "2022-10-14", y: "1481", t: "311" },
            { x: "2022-10-15", y: "814", t: "238" },
            { x: "2022-10-16", y: "1991", t: "344" },
            { x: "2022-10-17", y: "1580", t: "370" },
            { x: "2022-10-18", y: "1736", t: "334" },
            { x: "2022-10-19", y: "2003", t: "325" },
            { x: "2022-10-20", y: "1758", t: "317" },
            { x: "2022-10-21", y: "1589", t: "275" },
            { x: "2022-10-22", y: "670", t: "212" },
            { x: "2022-10-23", y: "1590", t: "282" },
            { x: "2022-10-24", y: "1453", t: "324" },
            { x: "2022-10-25", y: "1649", t: "314" },
            { x: "2022-10-26", y: "1801", t: "312" },
            { x: "2022-10-27", y: "1848", t: "305" },
            { x: "2022-10-28", y: "1242", t: "257" },
            { x: "2022-10-29", y: "629", t: "217" },
            { x: "2022-10-30", y: "1456", t: "292" },
            { x: "2022-10-31", y: "1390", t: "343" },
            { x: "2022-11-01", y: "1803", t: "344" },
            { x: "2022-11-02", y: "1952", t: "342" },
            { x: "2022-11-03", y: "1968", t: "333" },
            { x: "2022-11-04", y: "1799", t: "271" },
            { x: "2022-11-05", y: "670", t: "226" },
            { x: "2022-11-06", y: "1774", t: "299" },
            { x: "2022-11-07", y: "1257", t: "325" },
            { x: "2022-11-08", y: "1291", t: "304" },
            { x: "2022-11-09", y: "1876", t: "340" },
            { x: "2022-11-10", y: "1911", t: "322" },
            { x: "2022-11-11", y: "1477", t: "274" },
            { x: "2022-11-12", y: "674", t: "224" },
            { x: "2022-11-13", y: "1647", t: "299" },
            { x: "2022-11-14", y: "1415", t: "328" },
            { x: "2022-11-15", y: "1355", t: "309" },
            { x: "2022-11-16", y: "1696", t: "300" },
            { x: "2022-11-17", y: "1722", t: "295" },
            { x: "2022-11-18", y: "1324", t: "254" },
            { x: "2022-11-19", y: "557", t: "198" },
            { x: "2022-11-20", y: "1308", t: "236" },
            { x: "2022-11-21", y: "1011", t: "272" },
            { x: "2022-11-22", y: "965", t: "239" },
            { x: "2022-11-23", y: "839", t: "197" },
            { x: "2022-11-24", y: "441", t: "132" },
            { x: "2022-11-25", y: "682", t: "176" },
            { x: "2022-11-26", y: "517", t: "178" },
            { x: "2022-11-27", y: "1171", t: "242" },
            { x: "2022-11-28", y: "1071", t: "284" },
            { x: "2022-11-29", y: "1125", t: "266" },
            { x: "2022-11-30", y: "1138", t: "252" },
            { x: "2022-12-01", y: "1078", t: "225" },
            { x: "2022-12-02", y: "1037", t: "212" },
            { x: "2022-12-03", y: "428", t: "150" },
            { x: "2022-12-04", y: "682", t: "162" },
            { x: "2022-12-05", y: "450", t: "156" },
            { x: "2022-12-06", y: "461", t: "129" },
            { x: "2022-12-07", y: "588", t: "132" },
            { x: "2022-12-08", y: "453", t: "111" },
            { x: "2022-12-09", y: "407", t: "111" },
            { x: "2022-12-10", y: "195", t: "65" },
            { x: "2022-12-11", y: "188", t: "61" },
            { x: "2022-12-12", y: "46", t: "39" },
          ],
          borderWidth: "2",
          backgroundColor: "rgb(75, 192, 192)",
          borderColor: "rgb(75, 192, 192)",
          label: "Students participating in DIGITAL DISCUSSIONS",
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: "true",
          text: "DIGITAL DISCUSSIONS",
        },
      },
    },
  });

  const handleOpenModal = (id) => {
    // console.log("id", id);
    //   setShowModal(true);
    //   const fetchChartData = async (id) => {
    //     const data = await fetch(
    //       `https://bb-coprod-2.cc.uic.edu/dashboard/public/detail.php?index=${id}`
    //     );

    //     const json = await data.json();

    //     setChartData(json);
    //   };
    //   fetchChartData(id);
    // };
    // const handleCloseModal = () => {
    //   setShowModal(false);
    window.open(
      "https://bb-coprod-2.cc.uic.edu/dashboard/gallery/build/",
      "_blank"
    );
  };

  return (
    <>
      <div className="stats-wrapper">
        <div className="stats-wrapper-2">
          <div className="facts">
            <ul className="facts">
              {stats.map((stat, index) => (
                <li className="facts" key={index}>
                  <button
                    className="Stat-button"
                    onClick={() => {
                      handleOpenModal(stat.id);
                    }}
                  >
                    <h1 className="stat-text">
                      {stat["Stat Text"]}
                      <AnimatedNumbers
                        className="animated-container"
                        includeComma
                        animateToNumber={parseFloat(
                          stat["Stat Number"].replace(/,/g, "")
                        )}
                        fontStyle={{ fontSize: 40 }}
                      ></AnimatedNumbers>
                    </h1>
                    <h2 className="stat-text">{stat["Stat Domain"]}</h2>
                    <div className="stats-horizontal-rule"></div>
                    <span>
                      <p>{stat["Stat Explain"]}</p>
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* <ReactModal isOpen={showModal}>
        <div className="modal">
          <button onClick={handleCloseModal} className="modal-close-button">
            <span className="close">&times;</span>
          </button>
        </div>
        <div className="chart">
          <Chart data={chartData.data} options={chartData.options} />
        </div>
      </ReactModal> */}
    </>
  );
};

export default Stats;
