
import './App.css';
import React from 'react'
import { Header, Footer, Stats } from './containers';

import Navbar1 from './components/navbar/navbar1/Navbar1';


const App = () => {
  return (
    <div>
      <div className='App'>
        <div className='navbar'>
          <Navbar1 />
        </div>
        <div className='header'>
          <Header />
        </div>
        <div className='stats'>
          <Stats />
        </div>
        <div className='footer'>
          <Footer />
        </div>
      </div>

    </div>
  )
}

export default App