import React, { useState } from 'react';



const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [toggleAdmisssion, setToggleAdmisssion] = useState(false);
    const [toggleAcademics, setToggleAcademics] = useState(false);
    const [toggleResearch, setToggleResearch] = useState(false);
    const [toggleLife, setToggleLife] = useState(false);
    const [toggleAbout, setToggleAbout] = useState(false);
    const [toggleAdmisssionDrop, setToggleAdmisssionDrop] = useState(false);
    const [toggleAcademicsDrop, setToggleAcademicsDrop] = useState(false);
    const [toggleResearchDrop, setToggleResearchDrop] = useState(false);
    const [toggleLifeDrop, setToggleLifeDrop] = useState(false);
    const [toggleAboutDrop, setToggleAboutDrop] = useState(false);
    return (
        <nav className='nav'>
            <ul className={`menus ${toggleMenu ? "open" : ""}`}>
                <li className="menu-label-item">
                    <span className="menu-label">Menu</span>
                </li>
                <li className="menu-toggle-item">
                    <button type="button" tabIndex="-1" className={`menu-toggle ${toggleMenu ? "active" : ""}`} onClick={() => { setToggleMenu((prev) => !prev) }} aria-label="menu">
                        <span className="menu-toggle-icon"></span>
                    </button>
                </li>
                <li className={`menu-items ${toggleAdmisssion ? "open" : ""} ${toggleAdmisssionDrop ? "open" : ""}`}>
                    <a href="https://learning.uic.edu/" target="_blank" className="primary-list-anchor" onMouseOver={() => {
                        if (window.innerWidth > 1083) {
                            setToggleAdmisssionDrop((prev) => !prev)
                        }
                    }} onMouseOut={() => { if (window.innerWidth > 1083) { setToggleAdmisssionDrop((prev) => !prev) } }} rel="noreferrer"> { /*onMouseLeave={() => { if (window.innerWidth > 1083) { setToggleAdmisssionDrop((prev) => !prev) } }}*/}
                        Learning
                    </a>
                    <ul className={`primary-second-tier-list ${toggleAdmisssion ? "open" : ""}`}
                        onMouseOver={() => {
                            if (window.innerWidth > 1083) {
                                setToggleAdmisssionDrop((prev) => !prev)
                            }
                        }} onMouseOut={() => { if (window.innerWidth > 1083) { setToggleAdmisssionDrop((prev) => !prev) } }}
                    >
                        <li className={`primary-second-tier-list-item ${toggleAdmisssion ? "open" : ""}`}>
                            <a href="https://learning.uic.edu/resources/learning-management-system/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor" >
                                Learning Management System
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleAdmisssion ? "open" : ""}`}>
                            <a href="https://learning.uic.edu/resources/accessibility/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Accessibility in Teaching
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleAdmisssion ? "open" : ""}`}>
                            <a href="https://learning.uic.edu/resources/learning-analytics/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Learning Analytics
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleAdmisssion ? "open" : ""}`}>
                            <a href="https://learning.uic.edu/resources/virtual-classrooms/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Virtual Classrooms
                            </a>
                        </li>
                    </ul>
                    <div role="button" tabIndex="0" aria-label="expand Admissions & Aid" className={`expander ${toggleAdmisssion ? "open" : ""}`} onClick={() => { setToggleAdmisssion((prev) => !prev) }}></div>
                </li>
                <li className={`menu-items ${toggleAcademics ? "open" : ""} ${toggleAcademicsDrop ? "open" : ""}`}>
                    <a href="https://www.uic.edu/academics/" target="_blank" rel="noreferrer" className="primary-list-anchor" onMouseOver={() => {
                        if (window.innerWidth > 1083) {
                            setToggleAcademicsDrop((prev) => !prev)
                        }
                    }} onMouseOut={() => { if (window.innerWidth > 1083) { setToggleAcademicsDrop((prev) => !prev) } }}>
                        Academics
                    </a>
                    <ul className={`primary-second-tier-list ${toggleAcademics ? "open" : ""}`} onMouseOver={() => {
                        if (window.innerWidth > 1083) {
                            setToggleAcademicsDrop((prev) => !prev)
                        }
                    }} onMouseOut={() => {
                        if (window.innerWidth > 1083) {
                            setToggleAcademicsDrop((prev) => !prev)
                        }
                    }}>
                        <li className={`primary-second-tier-list-item ${toggleAcademics ? "open" : ""}`}>
                            <a href="https://www.uic.edu/academics/programs-of-study/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Programs of Study
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleAcademics ? "open" : ""}`}>
                            <a href="https://www.uic.edu/academics/colleges-schools/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Colleges & Schools
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleAcademics ? "open" : ""}`}>
                            <a href="https://www.uic.edu/academics/student-support/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Student Support
                            </a>
                        </li>
                    </ul>
                    <div role="button" tabIndex="0" aria-label="expand Academics" className={`expander ${toggleAcademics ? "open" : ""}`} onClick={() => { setToggleAcademics((prev) => !prev) }}></div>
                </li>
                <li className={`menu-items ${toggleResearch ? "open" : ""} ${toggleResearchDrop ? "open" : ""}`}>
                    <a href="https://www.uic.edu/research/" target="_blank" rel="noreferrer" className="primary-list-anchor" onMouseOver={() => {
                        if (window.innerWidth > 1083) {
                            setToggleResearchDrop((prev) => !prev)
                        }
                    }} onMouseOut={() => { if (window.innerWidth > 1083) { setToggleResearchDrop((prev) => !prev) } }}>
                        Research
                    </a>
                    <ul className={`primary-second-tier-list ${toggleResearch ? "open" : ""}`} onMouseOver={() => {
                        if (window.innerWidth > 1083) {
                            setToggleResearchDrop((prev) => !prev)
                        }
                    }} onMouseOut={() => { if (window.innerWidth > 1083) { setToggleResearchDrop((prev) => !prev) } }}>
                        <li className={`primary-second-tier-list-item ${toggleResearch ? "open" : ""}`}>
                            <a href="https://www.uic.edu/research/research-impact/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Research Impact
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleResearch ? "open" : ""}`}>
                            <a href="https://www.uic.edu/research/research-strengths/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Research Strengths
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleResearch ? "open" : ""}`}>
                            <a href="https://www.uic.edu/research/resources-facilities/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Resources & Facilities
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleResearch ? "open" : ""}`}>
                            <a href="https://www.uic.edu/research/student-research/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Student Research
                            </a>
                        </li>
                    </ul>
                    <div role="button" tabIndex="0" aria-label="expand Research" className={`expander ${toggleResearch ? "open" : ""}`} onClick={() => { setToggleResearch((prev) => !prev) }}></div>
                </li>
                <li className={`menu-items ${toggleLife ? "open" : ""} ${toggleLifeDrop ? "open" : ""}`}>
                    <a href="https://www.uic.edu/life-at-uic/" target="_blank" rel="noreferrer" className="primary-list-anchor" onMouseOver={() => {
                        if (window.innerWidth > 1083) {
                            setToggleLifeDrop((prev) => !prev)
                        }
                    }} onMouseOut={() => { if (window.innerWidth > 1083) { setToggleLifeDrop((prev) => !prev) } }}>
                        Life at UIC
                    </a>
                    <ul className={`primary-second-tier-list ${toggleLife ? "open" : ""}`} onMouseOver={() => {
                        if (window.innerWidth > 1083) {
                            setToggleLifeDrop((prev) => !prev)
                        }
                    }} onMouseOut={() => { if (window.innerWidth > 1083) { setToggleLifeDrop((prev) => !prev) } }}>
                        <li className="primary-second-tier-list-item">
                            <a href="https://www.uic.edu/life-at-uic/living-on-around-campus/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Living On & Around Campus</a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleLife ? "open" : ""}`}>
                            <a href="https://www.uic.edu/life-at-uic/campus-resources/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Campus Resources
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleLife ? "open" : ""}`}>
                            <a href="https://www.uic.edu/life-at-uic/exploring-chicago/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Exploring Chicago
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleLife ? "open" : ""}`}>
                            <a href="https://www.uic.edu/life-at-uic/city-transportation/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                City Transportation
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleLife ? "open" : ""}`}>
                            <a href="https://www.uic.edu/life-at-uic/essential-chicago/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Essential Chicago
                            </a>
                        </li>
                    </ul>
                    <div role="button" tabIndex="0" aria-label="expand Life at UIC" className={`expander ${toggleLife ? "open" : ""}`} onClick={() => { setToggleLife((prev) => !prev) }}></div>
                </li>
                <li className="menu-items">
                    <a href="https://www.uic.edu/health-care/" target="_blank" rel="noreferrer" className="primary-list-anchor">
                        Health Care
                    </a>
                </li>
                <li className="menu-items">
                    <a href="https://www.uic.edu/alumni/" target="_blank" rel="noreferrer" className="primary-list-anchor">
                        Alumni
                    </a>
                </li>
                <li className={`menu-items ${toggleAbout ? "open" : ""} ${toggleAboutDrop ? "open" : ""}`}>
                    <a id='signin-link' href="https://uic.blackboard.com/auth-saml/saml/login?apId=_150_1&redirectUrl=https%3A%2F%2Fuic.blackboard.com%2Fultra" className="primary-list-anchor" onMouseOver={() => {
                        if (window.innerWidth > 1083) {
                            setToggleAboutDrop((prev) => !prev)
                        }
                    }} onMouseOut={() => { if (window.innerWidth > 1083) { setToggleAboutDrop((prev) => !prev) } }}>
                        Login
                    </a>
                    <ul className={`primary-second-tier-list ${toggleAbout ? "open" : ""}`} onMouseOver={() => {
                        if (window.innerWidth > 1083) {
                            setToggleAboutDrop((prev) => !prev)
                        }
                    }} onMouseOut={() => { if (window.innerWidth > 1083) { setToggleAboutDrop((prev) => !prev) } }}>
                        <li className="primary-second-tier-list-item">
                            <a href="https://password.uic.edu/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Password Management
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleAbout ? "open" : ""}`}>
                            <a href="http://activatenetid.accc.uic.edu/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Activate UIC Netid
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleAbout ? "open" : ""}`}>
                            <a href="https://it.uic.edu/services-support/student-resources/email/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Student Email
                            </a>
                        </li>
                        <li className={`primary-second-tier-list-item ${toggleAbout ? "open" : ""}`}>
                            <a href="https://it.uic.edu/services-support/status/" target="_blank" rel="noreferrer" className="primary-second-tier-list-anchor">
                                Service Status
                            </a>
                        </li>
                    </ul>
                    <div role="button" tabIndex="0" aria-label="expand About" className={`expander ${toggleAbout ? "open" : ""}`} onClick={() => { setToggleAbout((prev) => !prev) }}></div>
                </li>


                {/* {menuItems.map((menu, index) => {
                    return <MenuItems items={menu} key={index} />;
                })} */}

            </ul>

        </nav>
    );
};

export default Navbar;